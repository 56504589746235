$body: #081e20;
$headings-font-weight: 300 !important;
$primary: #c3f458;
$primary-shadow: rgba(195, 244, 88, 0.2);
$primary-shadow-light: rgba(195, 244, 88, 0.1);
$light: #fff;
$dark: #081e20;

$headings-font-weight: 300 !important;
$a: #c3f458;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

.body {
  background-color: $body;
  font-family: Clashgrotesk, sans-serif !important;
  color: $light;
  overflow: auto !important;
}

.heading {
  margin: 10px 20px 40px;
  color: $primary;
  font-size: 8vh;
  line-height: 8vh;
  font-weight: 600 !important;
  text-align: center;
}

p {
  color: $light;
  font-weight: 400;
}

.textblock {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 22px;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.btn,
#dapp-modal .dapp-core-component__main__btn,
.upark-container .dapp-core-component__main__btn {
  padding: 16px 32px;
  border-radius: 100px;
  color: $primary;
  background-color: $body;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  -webkit-box-shadow: inset 0 0 10px $primary-shadow;
  -moz-box-shadow: inset 0 0 10px $primary-shadow;
  box-shadow: inset 0 0 10px $primary-shadow;
  border: 0;

  &:hover {
    color: $primary;
    -webkit-box-shadow: inset 0 0 10px $primary-shadow-light;
    -moz-box-shadow: inset 0 0 10px $primary-shadow-light;
    box-shadow: inset 0 0 10px $primary-shadow-light;
  }
}

.dotted-underline {
  border-bottom: 1px #667183 dashed;
}

.btn-primary,
.upark-container .dapp-core-component__main__btn-primary,
#dapp-modal .dapp-core-component__main__btn-primary,
.upark-container .dapp-core-component__main__btn-light,
#dapp-modal .dapp-core-component__main__btn-light,
.upark-container .dapp-core-component__main__btn-unlock,
.upark-container .dapp-core-component__main__btn-dark,
#dapp-modal .dapp-core-component__main__btn-dark {
  color: $dark;
  background-color: $primary;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-primary:hover,
.upark-container .dapp-core-component__main__btn-primary:hover,
#dapp-modal .dapp-core-component__main__btn-primary:hover,
.upark-container .dapp-core-component__main__btn-light:hover,
.upark-container .dapp-core-component__main__btn-unlock:hover,
.upark-container .dapp-core-component__main__btn-dark:hover,
#dapp-modal .dapp-core-component__main__btn-dark:hover,
#dapp-modal .dapp-core-component__dappModalStyles__dappModalCloseButton:hover,
.btn-primary:focus,
.upark-container .dapp-core-component__main__btn-primary:focus,
#dapp-modal .dapp-core-component__main__btn-primary:focus,
.upark-container .dapp-core-component__main__btn-light:focus,
.upark-container .dapp-core-component__main__btn-unlock:focus,
.upark-container .dapp-core-component__main__btn-dark:focus,
#dapp-modal .dapp-core-component__main__btn-dark:focus,
#dapp-modal .dapp-core-component__dappModalStyles__dappModalCloseButton:focus,
.btn-primary:active,
.upark-container .dapp-core-component__main__btn-primary:active,
#dapp-modal .dapp-core-component__main__btn-primary:active,
.upark-container .dapp-core-component__main__btn-light:active,
.upark-container .dapp-core-component__main__btn-unlock:active,
.upark-container .dapp-core-component__main__btn-dark:active,
#dapp-modal .dapp-core-component__main__btn-dark:active,
#dapp-modal .dapp-core-component__dappModalStyles__dappModalCloseButton:active {
  color: $dark !important;
  background-color: #aede2d !important;
  box-shadow: none;
}

.dapp-transaction-toast,
.dapp-core-component__transactionStatusToastStyles__transactions-status-toast-content {
  color: black;
}

.upark-container .dapp-core-component__main__pair-remove {
  right: 25px;
  top: 5px;
}

#dapp-modal .dapp-core-component__dappModalStyles__dappModalCloseButton {
  padding: 0px;
  right: 30px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.btn-sm {
  font-size: 14px;
  padding: 12px 32px;
}

.btn-xs {
  font-size: 14px;
  padding: 8px 16px;

  svg {
    vertical-align: middle;
  }
}

.upark-container .dapp-default-login-button,
.upark-container .dapp-wallet-connect-login-button,
.upark-container .dapp-ledger-login-button {
  margin: 5px 0 !important;
}

.upark-container
  .extension-login_noExtensionButtonContent.justify-content-between {
  justify-content: center !important;

  .extension-login_noExtensionButtonIcon {
    display: none;
  }
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.navbar-nav {
  margin-top: 0.5rem;
}

.nav-pills {
  padding: 0.5rem;
  border-radius: 2rem;
  background-color: $dark;
  -webkit-box-shadow: inset 0 0 10px $primary-shadow-light;
  -moz-box-shadow: inset 0 0 10px $primary-shadow-light;
  box-shadow: inset 0 0 10px $primary-shadow-light;
}

.nav-pills .nav-link {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-radius: 2rem;
  border-bottom: none !important;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.nav-link.active {
  color: $dark;
}

.nav-link:hover {
  color: $primary !important;
}

.nav-link.active:hover {
  color: $dark !important;
}

.navbar-light .navbar-nav .nav-link {
  color: $primary;
}

.stake-apr-container {
  display: inline-block;
}

/* Modal
-------------------------------------------------- */
#dapp-modal {
  z-index: 1050;
}

.modal-content,
#dapp-modal .dapp-modal-dialog-content,
#dapp-modal .dapp-core-component__signWithExtensionModalStyles__modal-container,
#dapp-modal .dapp-core-component__dappModalStyles__dappModalContent {
  background-color: $body;
  border-radius: 50px;
  padding: 20px;
  -webkit-box-shadow: inset 0 0 20px $primary-shadow;
  -moz-box-shadow: inset 0 0 20px $primary-shadow;
  box-shadow: inset 0 0 20px $primary-shadow;
  border: none;
}

#dapp-modal .dapp-core-component__dappModalStyles__dappModalHeader,
#dapp-modal .dapp-modal-dialog-header,
#dapp-modal .dapp-core-component__main__card,
.modal-footer {
  border: 0;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }
      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }
      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }
      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $light;

      &:hover {
        color: $primary;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: $primary;
        }
      }
    }
  }
}

/* Buy page
-------------------------------------------------- */
.page-header {
  margin: 60px 0;

  .page-name {
    font-size: 36px;
    font-weight: 600 !important;
    margin-bottom: 20px;
  }

  .page-description {
    font-size: 16px;
    font-weight: 400 !important;
  }
}

.swap-form {
  border-radius: 50px;
  padding: 20px;
  -webkit-box-shadow: inset 0 0 20px $primary-shadow;
  -moz-box-shadow: inset 0 0 20px $primary-shadow;
  box-shadow: inset 0 0 20px $primary-shadow;
}

.switch-btn {
  width: 50px;
  height: 50px;
  padding: 0 !important;
}

.apr-rewards-container {
  border-radius: 0.25rem;
  font-weight: 300;
  padding: 0.05em 0.4em;
}

.apr-container {
  background-color: #dd9300;
}

.locked-apr-container {
  background-color: #12a366;
}

.rc-slider {
  position: relative;
  height: 0.875rem;
  padding: 0.3125rem 0;
  width: 100%;
  border-radius: 0.375rem;
  touch-action: none;
  box-sizing: border-box;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding-bottom: 1.5rem;
  width: calc(100% - 0.8rem);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: $dark;
  height: 0.25rem;
  border-radius: 0.375rem;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: $primary;
}

.rc-slider-handle {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -0.3125rem;
  cursor: grab;
  border-radius: 50%;
  background-color: $primary;
  touch-action: pan-x;
}

.rc-slider-dot-active {
  background-color: $primary !important;
  border: 0.1875rem solid $dark !important;
}
.rc-slider-dot {
  position: absolute;
  bottom: -0.375rem;
  width: 0.875rem;
  height: 0.875rem;
  border: 0.1875rem solid #fff;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: transparent;
}

.rc-slider-mark {
  position: absolute;
  top: 1.125rem;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.swap-form-field {
  .input-group {
    padding: 10px 20px;
  }

  .input-amount {
    font-size: 16px;
    padding: 0%;
    border: 0;
    color: $light;
    background-color: transparent;
    width: 100%;
    -moz-appearance: textfield;

    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  .swap-from-feedback {
    margin-top: 10px;
    padding: 0 25px;
    font-size: 12px;
  }
}

.swap-form-group {
  padding: 5px;
  margin-bottom: 0;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 7px $primary-shadow;
  -moz-box-shadow: inset 0 0 7px $primary-shadow;
  box-shadow: inset 0 0 7px $primary-shadow;
}

.token-container {
  -webkit-box-shadow: inset 0 0 7px $primary-shadow;
  -moz-box-shadow: inset 0 0 7px $primary-shadow;
  box-shadow: inset 0 0 7px $primary-shadow;
  border-radius: 50px;
  padding: 5px;
  min-width: 165px;

  .token-name {
    font-size: 14px;
  }
}

.token-image {
  padding: 5px;
  margin-right: 10px;
  border-radius: 100%;
  -webkit-box-shadow: inset 0 0 7px $primary-shadow;
  -moz-box-shadow: inset 0 0 7px $primary-shadow;
  box-shadow: inset 0 0 7px $primary-shadow;

  .token-symbol {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
}

.stake-body {
  z-index: 2;
}

.stake-card-main {
  flex-direction: row;
  justify-content: center;
}

.stake-position-card {
  flex-direction: row;
  width: 50%;
}

.stake-divider {
  width: 100%;
  height: 0.5px;
  background-color: $primary-shadow-light;
  margin-top: 0;
  margin-bottom: 0.2rem;
}

.stake-footer {
  margin: 0;
  margin-top: -80px;
  padding: 10px;
  padding-top: 60px;

  .card-body {
    margin-top: 20px;
  }
}

.stake-form-select {
  padding: 5px;
  margin-bottom: 0;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 7px $primary-shadow;
  -moz-box-shadow: inset 0 0 7px $primary-shadow;
  box-shadow: inset 0 0 7px $primary-shadow;
  background-color: $dark;
  white-space: nowrap;
}

.token-select {
  display: block;
}

.select-options-container {
  border: gray !important;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 7px $primary-shadow;
  -moz-box-shadow: inset 0 0 7px $primary-shadow;
  box-shadow: inset 0 0 7px $primary-shadow;
  background-color: $dark;
  position: absolute;
  z-index: 1;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.stake-position-buttons {
  flex-direction: row;
  justify-content: flex-end;
  min-width: 300px;
}

.stake-buttons {
  flex-direction: row;
}

.btn-stake-action,
.btn-stake-position-action {
  margin-right: 0.5rem;
  margin-top: 0;
  max-height: 2.675rem;
  min-width: 6.8rem;
  padding: 12px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  pointer-events: all;
  &:disabled {
    pointer-events: none;
  }
}

.stake-farm-body,
.stake-position-farm-body {
  justify-content: center;
  flex-direction: column;
  margin-top: 0;
}

.stake-farm-header {
  margin-bottom: 0;
}

.arrow-row {
  visibility: visible;
  max-width: 100%;
}

.arrow-column {
  visibility: hidden;
  max-width: 0;
}

.validation-message {
  color: #de5d2d;
}

.no-hover {
  pointer-events: none;
}

.progress {
  color: black !important;
}

.notification-modal {
  color: black !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;

  .icon {
    color: $primary;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog,
#dapp-modal .dapp-modal-dialog-content {
  color: $light;

  * {
    border: none;
  }

  .dapp-core-component__dappModalStyles__dappModalHeader {
    font-size: 22px;
    background-color: transparent;
  }

  .card,
  .dapp-core-component__main__card {
    background-color: transparent;

    .card-title {
      font-size: 22px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    a {
      color: $primary;
    }

    table {
      color: $light;

      thead tr th {
        color: $light;
      }
    }

    #accessWalletBtn {
      color: $dark;
    }

    #accessWalletBtn svg g g path {
      fill: $dark;
    }
  }
}

.upark-container .dapp-core-component__main__link-second-style:hover svg {
  color: $primary;
}

.upark-container .dapp-core-component__main__text-primary {
  color: $primary;
}

.access-denied-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600 !important;

  .icon {
    color: $primary;
  }
}

.locked-token-info {
  flex-direction: row;

  .unlock-btn {
    margin-left: 0.5em;
  }
}

.locked-token-value {
  flex-direction: row;
  gap: 0.2em;
}

.upark-container .dapp-core-ui-component .custom-class-for-modals_wrapper {
  max-width: 540px;
}

// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .stake-buttons {
    margin-top: 0rem;
    flex-direction: column;
  }

  .btn-stake-action {
    margin-top: 0.5rem;
    margin-right: 0;
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .stake-position-buttons {
    flex-direction: column;
    min-width: 100px;
  }

  .btn-stake-position-action {
    margin-top: 0.5rem;
    margin-right: 0;
  }

  .stake-card-main {
    flex-direction: column;
    justify-content: stretch;
  }

  .stake-buttons {
    margin-top: 1rem;
  }

  .stake-farm-body {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.5em;
  }

  .stake-position-card {
    flex-direction: column;
  }

  .stake-farm-header {
    margin-bottom: 0.5em;
  }

  .stake-divider {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .arrow-row {
    visibility: hidden;
    max-width: 0;
  }

  .arrow-column {
    visibility: visible;
    max-width: 100%;
    margin-left: auto;
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .page-header {
    margin: 40px 0;
  }

  .navbar-brand svg {
    width: auto;
    height: 25px;
  }

  .btn-sm {
    padding: 10px 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .stake-form-select {
    font-size: 10px;
  }

  .token-select {
    display: none;
  }

  .navbar-brand svg {
    width: auto;
    height: 20px;
  }

  .swap-form {
    .card-body {
      padding: 10px;
    }
  }

  .token-container {
    min-width: unset;

    .token-image {
      margin-right: 0;

      .token-symbol {
        width: 35px;
        height: 35px;
      }
    }

    .token-name {
      display: none !important;
    }
  }

  .btn {
    padding: 12px 26px;
  }

  .switch-btn {
    width: 35px;
    height: 35px;
  }

  .modal-dialog {
    margin: 20px;

    .modal-header {
      .modal-title {
        font-size: 22px;
      }

      .btn {
        padding: 12px 14px;
        line-height: 1;
      }
    }

    .modal-body {
      // overflow-wrap: break-word;
    }
  }

  .locked-token-info {
    flex-direction: column;
    justify-content: start;
    flex-wrap: nowrap;

    .unlock-btn {
      margin-top: 0.5em;
      margin-left: 0;
    }
  }

  .locked-token-value {
    flex-direction: column;
    align-items: flex-end;
    gap: 0.2em;
  }
}

.ledger-login_cardBody {
  color: $light !important;
}

p.lead {
  word-break: break-all;
}

.break-word {
  overflow-wrap: break-word;
  font-family: monospace;
}

// .card-body a {
//   color: $primary;
// }

.modal-dialog,
.upark-container .dapp-modal-dialog-content {
  max-width: 600px;
}

.upark-container .dapp-core-ui-component .custom-class-for-modals_wrapper {
  padding: 0 !important;
  margin: 0 !important;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  color: $primary;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown > p {
  margin: 5px;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
}

.sale-progress {
  background-color: $dark;
  -webkit-box-shadow: inset 0 0 10px $primary-shadow-light;
  -moz-box-shadow: inset 0 0 10px $primary-shadow-light;
  box-shadow: inset 0 0 10px $primary-shadow-light;

  .progress-bar {
    background-color: $primary;
  }
}
